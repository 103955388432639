<div class="container">
  <h1 class="app-bar__title">
    <span class="app-bar__title-label">
      CROWNBASE
      <span class="app-bar__title-accent">| NXT</span></span
    >
  </h1>

  <rc-user-menu></rc-user-menu>
</div>
