import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { PopupComponent } from '@progress/kendo-angular-popup';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';
import { NotificationService } from '@core/services/notification.service';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { DatePipe } from '@progress/kendo-angular-intl';
import { KendoGridComponent } from '@shared/components/kendo-grid/kendo-grid.component';
import { State } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { CapitalizePipe } from '@core/pipes/capitalize.pipe';
import { HydraCollectionResponse } from '@core/models/data/HydraCollectionResponse';
import { environment } from '@environments/environment';
import { Notification } from '@core/models/component/notification';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { KendoMessageService } from '@core/services/kendo-message.service';

@Component({
  selector: 'rc-user-notifications',
  standalone: true,
  templateUrl: './user-notifications.component.html',
  styleUrl: './user-notifications.component.scss',
  imports: [AsyncPipe, ButtonComponent, PopupComponent, GridModule, DatePipe, NgClass, TranslateModule, CapitalizePipe],
  providers: [CapitalizePipe, TranslatePipe, KendoMessageService],
})
export class UserNotificationsComponent extends KendoGridComponent implements OnInit {
  notificationService = inject(NotificationService);
  showNotificationsPopUp = false;
  #httpClient = inject(HttpClient);
  gridData$: Observable<GridDataResult>;
  updateData$ = new BehaviorSubject<void>(undefined);
  loading: boolean;
  totalNotifications: number;
  accumulatedData: Notification[] = [];
  kendoMessageService = inject(KendoMessageService);

  constructor() {
    super();
    this.gridState = {
      skip: 0,
      take: 3,
    };
  }

  ngOnInit() {
    this.notificationService.init();
    this.notificationService.notifications$
      .subscribe(() => {
        this.accumulatedData = [];
        this.updateData$.next();
      });

    this.gridData$ = this.#getNotifications();
    this.updateData$.next();
  }

  onDataStateChange(state: State): void {
    this.gridState = state;
    this.accumulatedData = [];
    this.gridState.skip = 0;
    this.updateData$.next();
  }

  loadMore(): void {
    if (this.gridState.take && this.gridState.skip! + this.gridState.take < this.totalNotifications) {
      this.loading = true;
      //@ts-ignore
      this.gridState.skip += this.gridState.take;
      this.updateData$.next();
    }
  }

  toggleNotifications() {
    this.showNotificationsPopUp = !this.showNotificationsPopUp;
    this.kendoMessageService.setMessageForKey({
      key: 'kendo.grid.noRecords',
      message: { text: 'NO_NOTIFICATIONS', capitalize: true },
    });
    if (!this.showNotificationsPopUp) {
      this.#resetGrid();
      this.kendoMessageService.setMessageForKey({
        key: 'kendo.grid.noRecords',
        message: { text: 'NO_RECORDS', capitalize: true },
      });
    }
  }

  #getNotifications(): Observable<GridDataResult> {
    return this.updateData$.asObservable().pipe(
      tap(() => this.isLoadingGridData$.next(true)),
      switchMap(() => {
        return this.#httpClient.get<HydraCollectionResponse<Notification>>(
          `${environment.apiUrl}/notifications`,
          {
            params: this.convertGridStateToQueryParams(),
          },
        );
      }),
      map((response) => {
        this.loading = false;
        this.totalNotifications = response['hydra:totalItems'];

        this.accumulatedData = [...this.accumulatedData, ...response['hydra:member']];
        return {
          total: response['hydra:totalItems'],
          data: this.accumulatedData,
        };
      }),
      tap(() => this.isLoadingGridData$.next(false)),
    );
  }

  #resetGrid() {
    this.gridState = {
      skip: 0,
      take: 3,
    };
    this.accumulatedData = [];
    this.totalNotifications = 0;
    this.loading = false;
    this.isLoadingGridData$.next(false);
  }
}
