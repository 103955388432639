<div class="k-d-flex k-justify-content-center k-align-items-center">
  <rc-user-notifications></rc-user-notifications>
  <span href="#" class="k-grid-cols-4 user-menu__current-user">
  {{ (authService.user$ | async)?.name }}
    <ul class="user-menu__dropdown">
      @for (menuItem of menuItems; track menuItem) {
        <li (click)="menuItem.click()">{{ menuItem.text }}</li>
      }
    </ul>
  </span>
</div>
