import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SseService } from '@core/services/sse .service';
import { ApiHttpService } from '@core/services/api-http.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationsSubject = new BehaviorSubject<any[]>([]);
  notifications$ = this.notificationsSubject.asObservable();

  private sseSubscription: Subscription | null = null;
  private isInitialized = false;

  private sseService = inject(SseService);
  private http = inject(HttpClient);
  private cookieService = inject(CookieService);
  private ApiHttpService = inject(ApiHttpService);
  amountToRead: number;

  init() {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    this.fetchUserAndStartListening();
    this.getTotalAmountToRead();
  }

  markRead(notification: any) {
    this.ApiHttpService.put(`${notification['@id']}/read`, notification).subscribe({
      next: () => {
        this.getTotalAmountToRead();
      },
      error: (error) => {
        return false;
      },
    });
  }

  markAllAsRead() {
    this.ApiHttpService.put(`/notifications/read`, {}).subscribe({
      next: () => {
        this.notificationsSubject.next([...this.notificationsSubject.value]);
        this.getTotalAmountToRead();
      },
    });
  }

  private fetchUserAndStartListening() {
    this.http.get(environment.apiUrl + '/users/me').subscribe({
      next: (response: any) => {
        const userId = response.id;
        const mercureToken = response.mercureSubscribeToken.token;
        const mercureTokenExpiresAt = response.mercureSubscribeToken.expiresAt;

        this.setMercureCookie(mercureToken);
        this.startListening(userId);

        this.scheduleTokenRefresh(mercureTokenExpiresAt);
      },
      error: (error) => {
        console.error('Error fetching user info:', error);
      },
    });
  }

  private setMercureCookie(mercureToken: string) {
    this.cookieService.set('mercureAuthorization', mercureToken, {
      path: '/api/.well-known/mercure',
      secure: true,
      sameSite: 'Strict',
    });
  }

  private startListening(userId: number) {
    const notificationsUrl = `${environment.apiUrl}/.well-known/mercure?topic=/users/${userId}/notifications`;

    this.sseSubscription = this.sseService.getServerSentEvent(notificationsUrl).subscribe({
      next: (event: MessageEvent) => {
        const notificationData = JSON.parse(event.data);
        this.addNotification(notificationData);
      },
      error: (error) => {
        console.error('Error with SSE connection:', error);
      },
    });
  }

  private addNotification(notification: any) {
    this.getTotalAmountToRead();
    const currentNotifications = this.notificationsSubject.value;
    this.notificationsSubject.next([notification, ...currentNotifications]);
  }

  private scheduleTokenRefresh(expiresAt: string) {
    const expirationTime = new Date(expiresAt).getTime();
    const now = Date.now();
    const timeUntilExpiration = expirationTime - now;

    const refreshTime = timeUntilExpiration - 5 * 60 * 1000;

    if (refreshTime > 0) {
      setTimeout(() => {
        this.fetchUserAndStartListening();
      }, refreshTime);
    }
  }

  private getTotalAmountToRead() {
    this.ApiHttpService.get(`/notifications/amount_to_read`)
      .subscribe({
        next: (response: any) => {
          this.amountToRead = (response.body as AmountToReadResponse).amountToRead; // Type assertion
        },
        error: (error) => {
          console.error('Error fetching amount to read:', error); // Handle error
        },
      });
  }

}

interface AmountToReadResponse {
  amountToRead: number;
}
