/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "nl-BE",
  identity: {
    language: "nl",
    territory: "BE"
  },
  territory: "BE",
  numbers: {
    symbols: {
      decimal: ",",
      group: ".",
      list: ";",
      percentSign: "%",
      plusSign: "+",
      minusSign: "-",
      approximatelySign: "~",
      exponential: "E",
      superscriptingExponent: "×",
      perMille: "‰",
      infinity: "∞",
      nan: "NaN",
      timeSeparator: ":"
    },
    decimal: {
      patterns: ["n"],
      groupSize: [3]
    },
    scientific: {
      patterns: ["nEn"],
      groupSize: []
    },
    percent: {
      patterns: ["n%"],
      groupSize: [3]
    },
    currency: {
      patterns: ["$ n", "$ -n"],
      groupSize: [3],
      "unitPattern-count-one": "n $",
      "unitPattern-count-other": "n $"
    },
    accounting: {
      patterns: ["$ n", "($ n)"],
      groupSize: [3]
    },
    currencies: {
      ADP: {
        displayName: "Andorrese peseta",
        "displayName-count-one": "Andorrese peseta",
        "displayName-count-other": "Andorrese peseta",
        symbol: "ADP"
      },
      AED: {
        displayName: "Verenigde Arabische Emiraten-dirham",
        "displayName-count-one": "VAE-dirham",
        "displayName-count-other": "VAE-dirham",
        symbol: "AED"
      },
      AFA: {
        displayName: "Afghani (1927–2002)",
        "displayName-count-one": "Afghani (AFA)",
        "displayName-count-other": "Afghani (AFA)",
        symbol: "AFA"
      },
      AFN: {
        displayName: "Afghaanse afghani",
        "displayName-count-one": "Afghaanse afghani",
        "displayName-count-other": "Afghaanse afghani",
        symbol: "AFN",
        "symbol-alt-narrow": "؋"
      },
      ALK: {
        displayName: "Albanese lek (1946–1965)",
        "displayName-count-one": "Albanese lek (1946–1965)",
        "displayName-count-other": "Albanese lek (1946–1965)",
        symbol: "ALK"
      },
      ALL: {
        displayName: "Albanese lek",
        "displayName-count-one": "Albanese lek",
        "displayName-count-other": "Albanese lek",
        symbol: "ALL"
      },
      AMD: {
        displayName: "Armeense dram",
        "displayName-count-one": "Armeense dram",
        "displayName-count-other": "Armeense dram",
        symbol: "AMD",
        "symbol-alt-narrow": "֏"
      },
      ANG: {
        displayName: "Nederlands-Antilliaanse gulden",
        "displayName-count-one": "Nederlands-Antilliaanse gulden",
        "displayName-count-other": "Nederlands-Antilliaanse gulden",
        symbol: "ANG"
      },
      AOA: {
        displayName: "Angolese kwanza",
        "displayName-count-one": "Angolese kwanza",
        "displayName-count-other": "Angolese kwanza",
        symbol: "AOA",
        "symbol-alt-narrow": "Kz"
      },
      AOK: {
        displayName: "Angolese kwanza (1977–1990)",
        "displayName-count-one": "Angolese kwanza (1977–1990)",
        "displayName-count-other": "Angolese kwanza (1977–1990)",
        symbol: "AOK"
      },
      AON: {
        displayName: "Angolese nieuwe kwanza (1990–2000)",
        "displayName-count-one": "Angolese nieuwe kwanza (1990–2000)",
        "displayName-count-other": "Angolese nieuwe kwanza (1990–2000)",
        symbol: "AON"
      },
      AOR: {
        displayName: "Angolese kwanza reajustado (1995–1999)",
        "displayName-count-one": "Angolese kwanza reajustado (1995–1999)",
        "displayName-count-other": "Angolese kwanza reajustado (1995–1999)",
        symbol: "AOR"
      },
      ARA: {
        displayName: "Argentijnse austral",
        "displayName-count-one": "Argentijnse austral",
        "displayName-count-other": "Argentijnse austral",
        symbol: "ARA"
      },
      ARL: {
        displayName: "Argentijnse peso ley (1970–1983)",
        "displayName-count-one": "Argentijnse peso ley (1970–1983)",
        "displayName-count-other": "Argentijnse peso ley (1970–1983)",
        symbol: "ARL"
      },
      ARM: {
        displayName: "Argentijnse peso (1881–1970)",
        "displayName-count-one": "Argentijnse peso (1881–1970)",
        "displayName-count-other": "Argentijnse peso (1881–1970)",
        symbol: "ARM"
      },
      ARP: {
        displayName: "Argentijnse peso (1983–1985)",
        "displayName-count-one": "Argentijnse peso (1983–1985)",
        "displayName-count-other": "Argentijnse peso (1983–1985)",
        symbol: "ARP"
      },
      ARS: {
        displayName: "Argentijnse peso",
        "displayName-count-one": "Argentijnse peso",
        "displayName-count-other": "Argentijnse peso",
        symbol: "ARS",
        "symbol-alt-narrow": "$"
      },
      ATS: {
        displayName: "Oostenrijkse schilling",
        "displayName-count-one": "Oostenrijkse schilling",
        "displayName-count-other": "Oostenrijkse schilling",
        symbol: "ATS"
      },
      AUD: {
        displayName: "Australische dollar",
        "displayName-count-one": "Australische dollar",
        "displayName-count-other": "Australische dollar",
        symbol: "AU$",
        "symbol-alt-narrow": "$"
      },
      AWG: {
        displayName: "Arubaanse gulden",
        "displayName-count-one": "Arubaanse gulden",
        "displayName-count-other": "Arubaanse gulden",
        symbol: "AWG"
      },
      AZM: {
        displayName: "Azerbeidzjaanse manat (1993–2006)",
        "displayName-count-one": "Azerbeidzjaanse manat (1993–2006)",
        "displayName-count-other": "Azerbeidzjaanse manat (1993–2006)",
        symbol: "AZM"
      },
      AZN: {
        displayName: "Azerbeidzjaanse manat",
        "displayName-count-one": "Azerbeidzjaanse manat",
        "displayName-count-other": "Azerbeidzjaanse manat",
        symbol: "AZN",
        "symbol-alt-narrow": "₼"
      },
      BAD: {
        displayName: "Bosnische dinar",
        "displayName-count-one": "Bosnische dinar",
        "displayName-count-other": "Bosnische dinar",
        symbol: "BAD"
      },
      BAM: {
        displayName: "Bosnische convertibele mark",
        "displayName-count-one": "Bosnische convertibele mark",
        "displayName-count-other": "Bosnische convertibele mark",
        symbol: "BAM",
        "symbol-alt-narrow": "KM"
      },
      BAN: {
        displayName: "Nieuwe Bosnische dinar (1994–1997)",
        "displayName-count-one": "Nieuwe Bosnische dinar (1994–1997)",
        "displayName-count-other": "Nieuwe Bosnische dinar (1994–1997)",
        symbol: "BAN"
      },
      BBD: {
        displayName: "Barbadaanse dollar",
        "displayName-count-one": "Barbadaanse dollar",
        "displayName-count-other": "Barbadaanse dollar",
        symbol: "BBD",
        "symbol-alt-narrow": "$"
      },
      BDT: {
        displayName: "Bengalese taka",
        "displayName-count-one": "Bengalese taka",
        "displayName-count-other": "Bengalese taka",
        symbol: "BDT",
        "symbol-alt-narrow": "৳"
      },
      BEC: {
        displayName: "Belgische frank (convertibel)",
        "displayName-count-one": "Belgische frank (convertibel)",
        "displayName-count-other": "Belgische frank (convertibel)",
        symbol: "BEC"
      },
      BEF: {
        displayName: "Belgische frank",
        "displayName-count-one": "Belgische frank",
        "displayName-count-other": "Belgische frank",
        symbol: "BEF"
      },
      BEL: {
        displayName: "Belgische frank (financieel)",
        "displayName-count-one": "Belgische frank (financieel)",
        "displayName-count-other": "Belgische frank (financieel)",
        symbol: "BEL"
      },
      BGL: {
        displayName: "Bulgaarse harde lev",
        "displayName-count-one": "Bulgaarse harde lev",
        "displayName-count-other": "Bulgaarse harde lev",
        symbol: "BGL"
      },
      BGM: {
        displayName: "Bulgaarse socialistische lev",
        "displayName-count-one": "Bulgaarse socialistische lev",
        "displayName-count-other": "Bulgaarse socialistische lev",
        symbol: "BGM"
      },
      BGN: {
        displayName: "Bulgaarse lev",
        "displayName-count-one": "Bulgaarse lev",
        "displayName-count-other": "Bulgaarse leva",
        symbol: "BGN"
      },
      BGO: {
        displayName: "Bulgaarse lev (1879–1952)",
        "displayName-count-one": "Bulgaarse lev (1879–1952)",
        "displayName-count-other": "Bulgaarse lev (1879–1952)",
        symbol: "BGO"
      },
      BHD: {
        displayName: "Bahreinse dinar",
        "displayName-count-one": "Bahreinse dinar",
        "displayName-count-other": "Bahreinse dinar",
        symbol: "BHD"
      },
      BIF: {
        displayName: "Burundese frank",
        "displayName-count-one": "Burundese frank",
        "displayName-count-other": "Burundese frank",
        symbol: "BIF"
      },
      BMD: {
        displayName: "Bermuda-dollar",
        "displayName-count-one": "Bermuda-dollar",
        "displayName-count-other": "Bermuda-dollar",
        symbol: "BMD",
        "symbol-alt-narrow": "$"
      },
      BND: {
        displayName: "Bruneise dollar",
        "displayName-count-one": "Bruneise dollar",
        "displayName-count-other": "Bruneise dollar",
        symbol: "BND",
        "symbol-alt-narrow": "$"
      },
      BOB: {
        displayName: "Boliviaanse boliviano",
        "displayName-count-one": "Boliviaanse boliviano",
        "displayName-count-other": "Boliviaanse boliviano",
        symbol: "BOB",
        "symbol-alt-narrow": "Bs"
      },
      BOL: {
        displayName: "Boliviaanse boliviano (1863–1963)",
        "displayName-count-one": "Boliviaanse boliviano (1863–1963)",
        "displayName-count-other": "Boliviaanse boliviano (1863–1963)",
        symbol: "BOL"
      },
      BOP: {
        displayName: "Boliviaanse peso",
        "displayName-count-one": "Boliviaanse peso",
        "displayName-count-other": "Boliviaanse peso",
        symbol: "BOP"
      },
      BOV: {
        displayName: "Boliviaanse mvdol",
        "displayName-count-one": "Boliviaanse mvdol",
        "displayName-count-other": "Boliviaanse mvdol",
        symbol: "BOV"
      },
      BRB: {
        displayName: "Braziliaanse cruzeiro novo (1967–1986)",
        "displayName-count-one": "Braziliaanse cruzeiro novo (1967–1986)",
        "displayName-count-other": "Braziliaanse cruzeiro novo (1967–1986)",
        symbol: "BRB"
      },
      BRC: {
        displayName: "Braziliaanse cruzado",
        "displayName-count-one": "Braziliaanse cruzado",
        "displayName-count-other": "Braziliaanse cruzado",
        symbol: "BRC"
      },
      BRE: {
        displayName: "Braziliaanse cruzeiro (1990–1993)",
        "displayName-count-one": "Braziliaanse cruzeiro (1990–1993)",
        "displayName-count-other": "Braziliaanse cruzeiro (1990–1993)",
        symbol: "BRE"
      },
      BRL: {
        displayName: "Braziliaanse real",
        "displayName-count-one": "Braziliaanse real",
        "displayName-count-other": "Braziliaanse real",
        symbol: "R$",
        "symbol-alt-narrow": "R$"
      },
      BRN: {
        displayName: "Braziliaanse nieuwe cruzado (1989–1990)",
        "displayName-count-one": "Braziliaanse cruzado novo",
        "displayName-count-other": "Braziliaanse cruzado novo",
        symbol: "BRN"
      },
      BRR: {
        displayName: "Braziliaanse cruzeiro",
        "displayName-count-one": "Braziliaanse cruzeiro",
        "displayName-count-other": "Braziliaanse cruzeiro",
        symbol: "BRR"
      },
      BRZ: {
        displayName: "Braziliaanse cruzeiro (1942–1967)",
        "displayName-count-one": "Braziliaanse cruzeiro (1942–1967)",
        "displayName-count-other": "Braziliaanse cruzeiro (1942–1967)",
        symbol: "BRZ"
      },
      BSD: {
        displayName: "Bahamaanse dollar",
        "displayName-count-one": "Bahamaanse dollar",
        "displayName-count-other": "Bahamaanse dollar",
        symbol: "BSD",
        "symbol-alt-narrow": "$"
      },
      BTN: {
        displayName: "Bhutaanse ngultrum",
        "displayName-count-one": "Bhutaanse ngultrum",
        "displayName-count-other": "Bhutaanse ngultrum",
        symbol: "BTN"
      },
      BUK: {
        displayName: "Birmese kyat",
        "displayName-count-one": "Birmese kyat",
        "displayName-count-other": "Birmese kyat",
        symbol: "BUK"
      },
      BWP: {
        displayName: "Botswaanse pula",
        "displayName-count-one": "Botswaanse pula",
        "displayName-count-other": "Botswaanse pula",
        symbol: "BWP",
        "symbol-alt-narrow": "P"
      },
      BYB: {
        displayName: "Wit-Russische nieuwe roebel (1994–1999)",
        "displayName-count-one": "Wit-Russische nieuwe roebel (1994–1999)",
        "displayName-count-other": "Wit-Russische nieuwe roebel (1994–1999)",
        symbol: "BYB"
      },
      BYN: {
        displayName: "Belarussische roebel",
        "displayName-count-one": "Belarussische roebel",
        "displayName-count-other": "Belarussische roebel",
        symbol: "BYN",
        "symbol-alt-narrow": "р."
      },
      BYR: {
        displayName: "Wit-Russische roebel (2000–2016)",
        "displayName-count-one": "Wit-Russische roebel (2000–2016)",
        "displayName-count-other": "Wit-Russische roebel (2000–2016)",
        symbol: "BYR"
      },
      BZD: {
        displayName: "Belizaanse dollar",
        "displayName-count-one": "Belizaanse dollar",
        "displayName-count-other": "Belizaanse dollar",
        symbol: "BZD",
        "symbol-alt-narrow": "$"
      },
      CAD: {
        displayName: "Canadese dollar",
        "displayName-count-one": "Canadese dollar",
        "displayName-count-other": "Canadese dollar",
        symbol: "C$",
        "symbol-alt-narrow": "$"
      },
      CDF: {
        displayName: "Congolese frank",
        "displayName-count-one": "Congolese frank",
        "displayName-count-other": "Congolese frank",
        symbol: "CDF"
      },
      CHE: {
        displayName: "WIR euro",
        "displayName-count-one": "WIR euro",
        "displayName-count-other": "WIR euro",
        symbol: "CHE"
      },
      CHF: {
        displayName: "Zwitserse frank",
        "displayName-count-one": "Zwitserse frank",
        "displayName-count-other": "Zwitserse frank",
        symbol: "CHF"
      },
      CHW: {
        displayName: "WIR franc",
        "displayName-count-one": "WIR franc",
        "displayName-count-other": "WIR franc",
        symbol: "CHW"
      },
      CLE: {
        displayName: "Chileense escudo",
        "displayName-count-one": "Chileense escudo",
        "displayName-count-other": "Chileense escudo",
        symbol: "CLE"
      },
      CLF: {
        displayName: "Chileense unidades de fomento",
        "displayName-count-one": "Chileense unidades de fomento",
        "displayName-count-other": "Chileense unidades de fomento",
        symbol: "CLF"
      },
      CLP: {
        displayName: "Chileense peso",
        "displayName-count-one": "Chileense peso",
        "displayName-count-other": "Chileense peso",
        symbol: "CLP",
        "symbol-alt-narrow": "$"
      },
      CNH: {
        displayName: "Chinese yuan (offshore)",
        "displayName-count-one": "Chinese yuan (offshore)",
        "displayName-count-other": "Chinese yuan (offshore)",
        symbol: "CNH"
      },
      CNX: {
        displayName: "dollar van de Chinese Volksbank",
        "displayName-count-one": "dollar van de Chinese Volksbank",
        "displayName-count-other": "dollar van de Chinese Volksbank",
        symbol: "CNX"
      },
      CNY: {
        displayName: "Chinese yuan",
        "displayName-count-one": "Chinese yuan",
        "displayName-count-other": "Chinese yuan",
        symbol: "CN¥",
        "symbol-alt-narrow": "¥"
      },
      COP: {
        displayName: "Colombiaanse peso",
        "displayName-count-one": "Colombiaanse peso",
        "displayName-count-other": "Colombiaanse peso",
        symbol: "COP",
        "symbol-alt-narrow": "$"
      },
      COU: {
        displayName: "Unidad de Valor Real",
        "displayName-count-one": "Unidad de Valor Real",
        "displayName-count-other": "Unidad de Valor Real",
        symbol: "COU"
      },
      CRC: {
        displayName: "Costa Ricaanse colon",
        "displayName-count-one": "Costa Ricaanse colon",
        "displayName-count-other": "Costa Ricaanse colon",
        symbol: "CRC",
        "symbol-alt-narrow": "₡"
      },
      CSD: {
        displayName: "Oude Servische dinar",
        "displayName-count-one": "Oude Servische dinar",
        "displayName-count-other": "Oude Servische dinar",
        symbol: "CSD"
      },
      CSK: {
        displayName: "Tsjechoslowaakse harde koruna",
        "displayName-count-one": "Tsjechoslowaakse harde koruna",
        "displayName-count-other": "Tsjechoslowaakse harde koruna",
        symbol: "CSK"
      },
      CUC: {
        displayName: "Cubaanse convertibele peso",
        "displayName-count-one": "Cubaanse convertibele peso",
        "displayName-count-other": "Cubaanse convertibele peso",
        symbol: "CUC",
        "symbol-alt-narrow": "$"
      },
      CUP: {
        displayName: "Cubaanse peso",
        "displayName-count-one": "Cubaanse peso",
        "displayName-count-other": "Cubaanse peso",
        symbol: "CUP",
        "symbol-alt-narrow": "$"
      },
      CVE: {
        displayName: "Kaapverdische escudo",
        "displayName-count-one": "Kaapverdische escudo",
        "displayName-count-other": "Kaapverdische escudo",
        symbol: "CVE"
      },
      CYP: {
        displayName: "Cyprisch pond",
        "displayName-count-one": "Cyprisch pond",
        "displayName-count-other": "Cyprisch pond",
        symbol: "CYP"
      },
      CZK: {
        displayName: "Tsjechische kroon",
        "displayName-count-one": "Tsjechische kroon",
        "displayName-count-other": "Tsjechische kronen",
        symbol: "CZK",
        "symbol-alt-narrow": "Kč"
      },
      DDM: {
        displayName: "Oost-Duitse ostmark",
        "displayName-count-one": "Oost-Duitse ostmark",
        "displayName-count-other": "Oost-Duitse ostmark",
        symbol: "DDM"
      },
      DEM: {
        displayName: "Duitse mark",
        "displayName-count-one": "Duitse mark",
        "displayName-count-other": "Duitse mark",
        symbol: "DEM"
      },
      DJF: {
        displayName: "Djiboutiaanse frank",
        "displayName-count-one": "Djiboutiaanse frank",
        "displayName-count-other": "Djiboutiaanse frank",
        symbol: "DJF"
      },
      DKK: {
        displayName: "Deense kroon",
        "displayName-count-one": "Deense kroon",
        "displayName-count-other": "Deense kronen",
        symbol: "DKK",
        "symbol-alt-narrow": "kr"
      },
      DOP: {
        displayName: "Dominicaanse peso",
        "displayName-count-one": "Dominicaanse peso",
        "displayName-count-other": "Dominicaanse peso",
        symbol: "DOP",
        "symbol-alt-narrow": "$"
      },
      DZD: {
        displayName: "Algerijnse dinar",
        "displayName-count-one": "Algerijnse dinar",
        "displayName-count-other": "Algerijnse dinar",
        symbol: "DZD"
      },
      ECS: {
        displayName: "Ecuadoraanse sucre",
        "displayName-count-one": "Ecuadoraanse sucre",
        "displayName-count-other": "Ecuadoraanse sucre",
        symbol: "ECS"
      },
      ECV: {
        displayName: "Ecuadoraanse unidad de valor constante (UVC)",
        "displayName-count-one": "Ecuadoraanse unidad de valor constante (UVC)",
        "displayName-count-other": "Ecuadoraanse unidad de valor constante (UVC)",
        symbol: "ECV"
      },
      EEK: {
        displayName: "Estlandse kroon",
        "displayName-count-one": "Estlandse kroon",
        "displayName-count-other": "Estlandse kroon",
        symbol: "EEK"
      },
      EGP: {
        displayName: "Egyptisch pond",
        "displayName-count-one": "Egyptisch pond",
        "displayName-count-other": "Egyptisch pond",
        symbol: "EGP",
        "symbol-alt-narrow": "E£"
      },
      ERN: {
        displayName: "Eritrese nakfa",
        "displayName-count-one": "Eritrese nakfa",
        "displayName-count-other": "Eritrese nakfa",
        symbol: "ERN"
      },
      ESA: {
        displayName: "Spaanse peseta (account A)",
        "displayName-count-one": "Spaanse peseta (account A)",
        "displayName-count-other": "Spaanse peseta (account A)",
        symbol: "ESA"
      },
      ESB: {
        displayName: "Spaanse peseta (convertibele account)",
        "displayName-count-one": "Spaanse peseta (convertibele account)",
        "displayName-count-other": "Spaanse peseta (convertibele account)",
        symbol: "ESB"
      },
      ESP: {
        displayName: "Spaanse peseta",
        "displayName-count-one": "Spaanse peseta",
        "displayName-count-other": "Spaanse peseta",
        symbol: "ESP",
        "symbol-alt-narrow": "₧"
      },
      ETB: {
        displayName: "Ethiopische birr",
        "displayName-count-one": "Ethiopische birr",
        "displayName-count-other": "Ethiopische birr",
        symbol: "ETB"
      },
      EUR: {
        displayName: "Euro",
        "displayName-count-one": "euro",
        "displayName-count-other": "euro",
        symbol: "€",
        "symbol-alt-narrow": "€"
      },
      FIM: {
        displayName: "Finse markka",
        "displayName-count-one": "Finse markka",
        "displayName-count-other": "Finse markka",
        symbol: "FIM"
      },
      FJD: {
        displayName: "Fiji-dollar",
        "displayName-count-one": "Fiji-dollar",
        "displayName-count-other": "Fiji-dollar",
        symbol: "FJ$",
        "symbol-alt-narrow": "$"
      },
      FKP: {
        displayName: "Falklandeilands pond",
        "displayName-count-one": "Falklandeilands pond",
        "displayName-count-other": "Falklandeilands pond",
        symbol: "FKP",
        "symbol-alt-narrow": "£"
      },
      FRF: {
        displayName: "Franse franc",
        "displayName-count-one": "Franse franc",
        "displayName-count-other": "Franse franc",
        symbol: "FRF"
      },
      GBP: {
        displayName: "Brits pond",
        "displayName-count-one": "Brits pond",
        "displayName-count-other": "Brits pond",
        symbol: "£",
        "symbol-alt-narrow": "£"
      },
      GEK: {
        displayName: "Georgische kupon larit",
        "displayName-count-one": "Georgische kupon larit",
        "displayName-count-other": "Georgische kupon larit",
        symbol: "GEK"
      },
      GEL: {
        displayName: "Georgische lari",
        "displayName-count-one": "Georgische lari",
        "displayName-count-other": "Georgische lari",
        symbol: "GEL",
        "symbol-alt-narrow": "₾",
        "symbol-alt-variant": "ლ"
      },
      GHC: {
        displayName: "Ghanese cedi (1979–2007)",
        "displayName-count-one": "Ghanese cedi (1979–2007)",
        "displayName-count-other": "Ghanese cedi (1979–2007)",
        symbol: "GHC"
      },
      GHS: {
        displayName: "Ghanese cedi",
        "displayName-count-one": "Ghanese cedi",
        "displayName-count-other": "Ghanese cedi",
        symbol: "GHS",
        "symbol-alt-narrow": "GH₵"
      },
      GIP: {
        displayName: "Gibraltarees pond",
        "displayName-count-one": "Gibraltarees pond",
        "displayName-count-other": "Gibraltarees pond",
        symbol: "GIP",
        "symbol-alt-narrow": "£"
      },
      GMD: {
        displayName: "Gambiaanse dalasi",
        "displayName-count-one": "Gambiaanse dalasi",
        "displayName-count-other": "Gambiaanse dalasi",
        symbol: "GMD"
      },
      GNF: {
        displayName: "Guinese frank",
        "displayName-count-one": "Guinese frank",
        "displayName-count-other": "Guinese frank",
        symbol: "GNF",
        "symbol-alt-narrow": "FG"
      },
      GNS: {
        displayName: "Guinese syli",
        "displayName-count-one": "Guinese syli",
        "displayName-count-other": "Guinese syli",
        symbol: "GNS"
      },
      GQE: {
        displayName: "Equatoriaal-Guinese ekwele guineana",
        "displayName-count-one": "Equatoriaal-Guinese ekwele guineana",
        "displayName-count-other": "Equatoriaal-Guinese ekwele guineana",
        symbol: "GQE"
      },
      GRD: {
        displayName: "Griekse drachme",
        "displayName-count-one": "Griekse drachme",
        "displayName-count-other": "Griekse drachme",
        symbol: "GRD"
      },
      GTQ: {
        displayName: "Guatemalteekse quetzal",
        "displayName-count-one": "Guatemalteekse quetzal",
        "displayName-count-other": "Guatemalteekse quetzal",
        symbol: "GTQ",
        "symbol-alt-narrow": "Q"
      },
      GWE: {
        displayName: "Portugees-Guinese escudo",
        "displayName-count-one": "Portugees-Guinese escudo",
        "displayName-count-other": "Portugees-Guinese escudo",
        symbol: "GWE"
      },
      GWP: {
        displayName: "Guinee-Bissause peso",
        "displayName-count-one": "Guinee-Bissause peso",
        "displayName-count-other": "Guinee-Bissause peso",
        symbol: "GWP"
      },
      GYD: {
        displayName: "Guyaanse dollar",
        "displayName-count-one": "Guyaanse dollar",
        "displayName-count-other": "Guyaanse dollar",
        symbol: "GYD",
        "symbol-alt-narrow": "$"
      },
      HKD: {
        displayName: "Hongkongse dollar",
        "displayName-count-one": "Hongkongse dollar",
        "displayName-count-other": "Hongkongse dollar",
        symbol: "HK$",
        "symbol-alt-narrow": "$"
      },
      HNL: {
        displayName: "Hondurese lempira",
        "displayName-count-one": "Hondurese lempira",
        "displayName-count-other": "Hondurese lempira",
        symbol: "HNL",
        "symbol-alt-narrow": "L"
      },
      HRD: {
        displayName: "Kroatische dinar",
        "displayName-count-one": "Kroatische dinar",
        "displayName-count-other": "Kroatische dinar",
        symbol: "HRD"
      },
      HRK: {
        displayName: "Kroatische kuna",
        "displayName-count-one": "Kroatische kuna",
        "displayName-count-other": "Kroatische kuna",
        symbol: "HRK",
        "symbol-alt-narrow": "kn"
      },
      HTG: {
        displayName: "Haïtiaanse gourde",
        "displayName-count-one": "Haïtiaanse gourde",
        "displayName-count-other": "Haïtiaanse gourde",
        symbol: "HTG"
      },
      HUF: {
        displayName: "Hongaarse forint",
        "displayName-count-one": "Hongaarse forint",
        "displayName-count-other": "Hongaarse forint",
        symbol: "HUF",
        "symbol-alt-narrow": "Ft"
      },
      IDR: {
        displayName: "Indonesische roepia",
        "displayName-count-one": "Indonesische roepia",
        "displayName-count-other": "Indonesische roepia",
        symbol: "IDR",
        "symbol-alt-narrow": "Rp"
      },
      IEP: {
        displayName: "Iers pond",
        "displayName-count-one": "Iers pond",
        "displayName-count-other": "Iers pond",
        symbol: "IEP"
      },
      ILP: {
        displayName: "Israëlisch pond",
        "displayName-count-one": "Israëlisch pond",
        "displayName-count-other": "Israëlisch pond",
        symbol: "ILP"
      },
      ILR: {
        displayName: "Israëlische sjekel (1980–1985)",
        "displayName-count-one": "Israëlische sjekel (1980–1985)",
        "displayName-count-other": "Israëlische sjekel (1980–1985)",
        symbol: "ILR"
      },
      ILS: {
        displayName: "Israëlische nieuwe shekel",
        "displayName-count-one": "Israëlische nieuwe shekel",
        "displayName-count-other": "Israëlische nieuwe shekel",
        symbol: "₪",
        "symbol-alt-narrow": "₪"
      },
      INR: {
        displayName: "Indiase roepie",
        "displayName-count-one": "Indiase roepie",
        "displayName-count-other": "Indiase roepie",
        symbol: "₹",
        "symbol-alt-narrow": "₹"
      },
      IQD: {
        displayName: "Iraakse dinar",
        "displayName-count-one": "Iraakse dinar",
        "displayName-count-other": "Iraakse dinar",
        symbol: "IQD"
      },
      IRR: {
        displayName: "Iraanse rial",
        "displayName-count-one": "Iraanse rial",
        "displayName-count-other": "Iraanse rial",
        symbol: "IRR"
      },
      ISJ: {
        displayName: "IJslandse kroon (1918–1981)",
        "displayName-count-one": "IJslandse kroon (1918–1981)",
        "displayName-count-other": "IJslandse kronen (1918–1981)",
        symbol: "ISJ"
      },
      ISK: {
        displayName: "IJslandse kroon",
        "displayName-count-one": "IJslandse kroon",
        "displayName-count-other": "IJslandse kronen",
        symbol: "ISK",
        "symbol-alt-narrow": "kr"
      },
      ITL: {
        displayName: "Italiaanse lire",
        "displayName-count-one": "Italiaanse lire",
        "displayName-count-other": "Italiaanse lire",
        symbol: "ITL"
      },
      JMD: {
        displayName: "Jamaicaanse dollar",
        "displayName-count-one": "Jamaicaanse dollar",
        "displayName-count-other": "Jamaicaanse dollar",
        symbol: "JMD",
        "symbol-alt-narrow": "$"
      },
      JOD: {
        displayName: "Jordaanse dinar",
        "displayName-count-one": "Jordaanse dinar",
        "displayName-count-other": "Jordaanse dinar",
        symbol: "JOD"
      },
      JPY: {
        displayName: "Japanse yen",
        "displayName-count-one": "Japanse yen",
        "displayName-count-other": "Japanse yen",
        symbol: "JP¥",
        "symbol-alt-narrow": "¥"
      },
      KES: {
        displayName: "Keniaanse shilling",
        "displayName-count-one": "Keniaanse shilling",
        "displayName-count-other": "Keniaanse shilling",
        symbol: "KES"
      },
      KGS: {
        displayName: "Kirgizische som",
        "displayName-count-one": "Kirgizische som",
        "displayName-count-other": "Kirgizische som",
        symbol: "KGS"
      },
      KHR: {
        displayName: "Cambodjaanse riel",
        "displayName-count-one": "Cambodjaanse riel",
        "displayName-count-other": "Cambodjaanse riel",
        symbol: "KHR",
        "symbol-alt-narrow": "៛"
      },
      KMF: {
        displayName: "Comorese frank",
        "displayName-count-one": "Comorese frank",
        "displayName-count-other": "Comorese frank",
        symbol: "KMF",
        "symbol-alt-narrow": "CF"
      },
      KPW: {
        displayName: "Noord-Koreaanse won",
        "displayName-count-one": "Noord-Koreaanse won",
        "displayName-count-other": "Noord-Koreaanse won",
        symbol: "KPW",
        "symbol-alt-narrow": "₩"
      },
      KRH: {
        displayName: "Zuid-Koreaanse hwan (1953–1962)",
        "displayName-count-one": "Zuid-Koreaanse hwan (1953–1962)",
        "displayName-count-other": "Zuid-Koreaanse hwan (1953–1962)",
        symbol: "KRH"
      },
      KRO: {
        displayName: "Oude Zuid-Koreaanse won (1945–1953)",
        "displayName-count-one": "oude Zuid-Koreaanse won (1945–1953)",
        "displayName-count-other": "oude Zuid-Koreaanse won (1945–1953)",
        symbol: "KRO"
      },
      KRW: {
        displayName: "Zuid-Koreaanse won",
        "displayName-count-one": "Zuid-Koreaanse won",
        "displayName-count-other": "Zuid-Koreaanse won",
        symbol: "₩",
        "symbol-alt-narrow": "₩"
      },
      KWD: {
        displayName: "Koeweitse dinar",
        "displayName-count-one": "Koeweitse dinar",
        "displayName-count-other": "Koeweitse dinar",
        symbol: "KWD"
      },
      KYD: {
        displayName: "Kaaimaneilandse dollar",
        "displayName-count-one": "Kaaimaneilandse dollar",
        "displayName-count-other": "Kaaimaneilandse dollar",
        symbol: "KYD",
        "symbol-alt-narrow": "$"
      },
      KZT: {
        displayName: "Kazachse tenge",
        "displayName-count-one": "Kazachse tenge",
        "displayName-count-other": "Kazachse tenge",
        symbol: "KZT",
        "symbol-alt-narrow": "₸"
      },
      LAK: {
        displayName: "Laotiaanse kip",
        "displayName-count-one": "Laotiaanse kip",
        "displayName-count-other": "Laotiaanse kip",
        symbol: "LAK",
        "symbol-alt-narrow": "₭"
      },
      LBP: {
        displayName: "Libanees pond",
        "displayName-count-one": "Libanees pond",
        "displayName-count-other": "Libanees pond",
        symbol: "LBP",
        "symbol-alt-narrow": "L£"
      },
      LKR: {
        displayName: "Sri Lankaanse roepie",
        "displayName-count-one": "Sri Lankaanse roepie",
        "displayName-count-other": "Sri Lankaanse roepie",
        symbol: "LKR",
        "symbol-alt-narrow": "Rs"
      },
      LRD: {
        displayName: "Liberiaanse dollar",
        "displayName-count-one": "Liberiaanse dollar",
        "displayName-count-other": "Liberiaanse dollar",
        symbol: "LRD",
        "symbol-alt-narrow": "$"
      },
      LSL: {
        displayName: "Lesothaanse loti",
        "displayName-count-one": "Lesothaanse loti",
        "displayName-count-other": "Lesothaanse loti",
        symbol: "LSL"
      },
      LTL: {
        displayName: "Litouwse litas",
        "displayName-count-one": "Litouwse litas",
        "displayName-count-other": "Litouwse litas",
        symbol: "LTL",
        "symbol-alt-narrow": "Lt"
      },
      LTT: {
        displayName: "Litouwse talonas",
        "displayName-count-one": "Litouwse talonas",
        "displayName-count-other": "Litouwse talonas",
        symbol: "LTT"
      },
      LUC: {
        displayName: "Luxemburgse convertibele franc",
        "displayName-count-one": "Luxemburgse convertibele franc",
        "displayName-count-other": "Luxemburgse convertibele franc",
        symbol: "LUC"
      },
      LUF: {
        displayName: "Luxemburgse frank",
        "displayName-count-one": "Luxemburgse frank",
        "displayName-count-other": "Luxemburgse frank",
        symbol: "LUF"
      },
      LUL: {
        displayName: "Luxemburgse financiële franc",
        "displayName-count-one": "Luxemburgse financiële franc",
        "displayName-count-other": "Luxemburgse financiële franc",
        symbol: "LUL"
      },
      LVL: {
        displayName: "Letse lats",
        "displayName-count-one": "Letse lats",
        "displayName-count-other": "Letse lats",
        symbol: "LVL",
        "symbol-alt-narrow": "Ls"
      },
      LVR: {
        displayName: "Letse roebel",
        "displayName-count-one": "Letse roebel",
        "displayName-count-other": "Letse roebel",
        symbol: "LVR"
      },
      LYD: {
        displayName: "Libische dinar",
        "displayName-count-one": "Libische dinar",
        "displayName-count-other": "Libische dinar",
        symbol: "LYD"
      },
      MAD: {
        displayName: "Marokkaanse dirham",
        "displayName-count-one": "Marokkaanse dirham",
        "displayName-count-other": "Marokkaanse dirham",
        symbol: "MAD"
      },
      MAF: {
        displayName: "Marokkaanse franc",
        "displayName-count-one": "Marokkaanse franc",
        "displayName-count-other": "Marokkaanse franc",
        symbol: "MAF"
      },
      MCF: {
        displayName: "Monegaskische frank",
        "displayName-count-one": "Monegaskische frank",
        "displayName-count-other": "Monegaskische frank",
        symbol: "MCF"
      },
      MDC: {
        displayName: "Moldavische cupon",
        "displayName-count-one": "Moldavische cupon",
        "displayName-count-other": "Moldavische cupon",
        symbol: "MDC"
      },
      MDL: {
        displayName: "Moldavische leu",
        "displayName-count-one": "Moldavische leu",
        "displayName-count-other": "Moldavische leu",
        symbol: "MDL"
      },
      MGA: {
        displayName: "Malagassische ariary",
        "displayName-count-one": "Malagassische ariary",
        "displayName-count-other": "Malagassische ariary",
        symbol: "MGA",
        "symbol-alt-narrow": "Ar"
      },
      MGF: {
        displayName: "Malagassische franc",
        "displayName-count-one": "Malagassische franc",
        "displayName-count-other": "Malagassische franc",
        symbol: "MGF"
      },
      MKD: {
        displayName: "Macedonische denar",
        "displayName-count-one": "Macedonische denar",
        "displayName-count-other": "Macedonische denar",
        symbol: "MKD"
      },
      MKN: {
        displayName: "Macedonische denar (1992–1993)",
        "displayName-count-one": "Macedonische denar (1992–1993)",
        "displayName-count-other": "Macedonische denar (1992–1993)",
        symbol: "MKN"
      },
      MLF: {
        displayName: "Malinese franc",
        "displayName-count-one": "Malinese franc",
        "displayName-count-other": "Malinese franc",
        symbol: "MLF"
      },
      MMK: {
        displayName: "Myanmarese kyat",
        "displayName-count-one": "Myanmarese kyat",
        "displayName-count-other": "Myanmarese kyat",
        symbol: "MMK",
        "symbol-alt-narrow": "K"
      },
      MNT: {
        displayName: "Mongoolse tugrik",
        "displayName-count-one": "Mongoolse tugrik",
        "displayName-count-other": "Mongoolse tugrik",
        symbol: "MNT",
        "symbol-alt-narrow": "₮"
      },
      MOP: {
        displayName: "Macause pataca",
        "displayName-count-one": "Macause pataca",
        "displayName-count-other": "Macause pataca",
        symbol: "MOP"
      },
      MRO: {
        displayName: "Mauritaanse ouguiya (1973–2017)",
        "displayName-count-one": "Mauritaanse ouguiya (1973–2017)",
        "displayName-count-other": "Mauritaanse ouguiya (1973–2017)",
        symbol: "MRO"
      },
      MRU: {
        displayName: "Mauritaanse ouguiya",
        "displayName-count-one": "Mauritaanse ouguiya",
        "displayName-count-other": "Mauritaanse ouguiya",
        symbol: "MRU"
      },
      MTL: {
        displayName: "Maltese lire",
        "displayName-count-one": "Maltese lire",
        "displayName-count-other": "Maltese lire",
        symbol: "MTL"
      },
      MTP: {
        displayName: "Maltees pond",
        "displayName-count-one": "Maltees pond",
        "displayName-count-other": "Maltees pond",
        symbol: "MTP"
      },
      MUR: {
        displayName: "Mauritiaanse roepie",
        "displayName-count-one": "Mauritiaanse roepie",
        "displayName-count-other": "Mauritiaanse roepie",
        symbol: "MUR",
        "symbol-alt-narrow": "Rs"
      },
      MVP: {
        displayName: "Maldivische roepie",
        "displayName-count-one": "Maldivische roepie",
        "displayName-count-other": "Maldivische roepie",
        symbol: "MVP"
      },
      MVR: {
        displayName: "Maldivische rufiyaa",
        "displayName-count-one": "Maldivische rufiyaa",
        "displayName-count-other": "Maldivische rufiyaa",
        symbol: "MVR"
      },
      MWK: {
        displayName: "Malawische kwacha",
        "displayName-count-one": "Malawische kwacha",
        "displayName-count-other": "Malawische kwacha",
        symbol: "MWK"
      },
      MXN: {
        displayName: "Mexicaanse peso",
        "displayName-count-one": "Mexicaanse peso",
        "displayName-count-other": "Mexicaanse peso",
        symbol: "MX$",
        "symbol-alt-narrow": "$"
      },
      MXP: {
        displayName: "Mexicaanse zilveren peso (1861–1992)",
        "displayName-count-one": "Mexicaanse zilveren peso (1861–1992)",
        "displayName-count-other": "Mexicaanse zilveren peso (1861–1992)",
        symbol: "MXP"
      },
      MXV: {
        displayName: "Mexicaanse unidad de inversion (UDI)",
        "displayName-count-one": "Mexicaanse unidad de inversion (UDI)",
        "displayName-count-other": "Mexicaanse unidad de inversion (UDI)",
        symbol: "MXV"
      },
      MYR: {
        displayName: "Maleisische ringgit",
        "displayName-count-one": "Maleisische ringgit",
        "displayName-count-other": "Maleisische ringgit",
        symbol: "MYR",
        "symbol-alt-narrow": "RM"
      },
      MZE: {
        displayName: "Mozambikaanse escudo",
        "displayName-count-one": "Mozambikaanse escudo",
        "displayName-count-other": "Mozambikaanse escudo",
        symbol: "MZE"
      },
      MZM: {
        displayName: "Oude Mozambikaanse metical",
        "displayName-count-one": "Oude Mozambikaanse metical",
        "displayName-count-other": "Oude Mozambikaanse metical",
        symbol: "MZM"
      },
      MZN: {
        displayName: "Mozambikaanse metical",
        "displayName-count-one": "Mozambikaanse metical",
        "displayName-count-other": "Mozambikaanse metical",
        symbol: "MZN"
      },
      NAD: {
        displayName: "Namibische dollar",
        "displayName-count-one": "Namibische dollar",
        "displayName-count-other": "Namibische dollar",
        symbol: "NAD",
        "symbol-alt-narrow": "$"
      },
      NGN: {
        displayName: "Nigeriaanse naira",
        "displayName-count-one": "Nigeriaanse naira",
        "displayName-count-other": "Nigeriaanse naira",
        symbol: "NGN",
        "symbol-alt-narrow": "₦"
      },
      NIC: {
        displayName: "Nicaraguaanse córdoba (1988–1991)",
        "displayName-count-one": "Nicaraguaanse córdoba (1988–1991)",
        "displayName-count-other": "Nicaraguaanse córdoba (1988–1991)",
        symbol: "NIC"
      },
      NIO: {
        displayName: "Nicaraguaanse córdoba",
        "displayName-count-one": "Nicaraguaanse córdoba",
        "displayName-count-other": "Nicaraguaanse córdoba",
        symbol: "NIO",
        "symbol-alt-narrow": "C$"
      },
      NLG: {
        displayName: "Nederlandse gulden",
        "displayName-count-one": "Nederlandse gulden",
        "displayName-count-other": "Nederlandse gulden",
        symbol: "NLG"
      },
      NOK: {
        displayName: "Noorse kroon",
        "displayName-count-one": "Noorse kroon",
        "displayName-count-other": "Noorse kronen",
        symbol: "NOK",
        "symbol-alt-narrow": "kr"
      },
      NPR: {
        displayName: "Nepalese roepie",
        "displayName-count-one": "Nepalese roepie",
        "displayName-count-other": "Nepalese roepie",
        symbol: "NPR",
        "symbol-alt-narrow": "Rs"
      },
      NZD: {
        displayName: "Nieuw-Zeelandse dollar",
        "displayName-count-one": "Nieuw-Zeelandse dollar",
        "displayName-count-other": "Nieuw-Zeelandse dollar",
        symbol: "NZ$",
        "symbol-alt-narrow": "$"
      },
      OMR: {
        displayName: "Omaanse rial",
        "displayName-count-one": "Omaanse rial",
        "displayName-count-other": "Omaanse rial",
        symbol: "OMR"
      },
      PAB: {
        displayName: "Panamese balboa",
        "displayName-count-one": "Panamese balboa",
        "displayName-count-other": "Panamese balboa",
        symbol: "PAB"
      },
      PEI: {
        displayName: "Peruaanse inti",
        "displayName-count-one": "Peruaanse inti",
        "displayName-count-other": "Peruaanse inti",
        symbol: "PEI"
      },
      PEN: {
        displayName: "Peruaanse sol",
        "displayName-count-one": "Peruaanse sol",
        "displayName-count-other": "Peruaanse sol",
        symbol: "PEN"
      },
      PES: {
        displayName: "Peruaanse sol (1863–1965)",
        "displayName-count-one": "Peruaanse sol (1863–1965)",
        "displayName-count-other": "Peruaanse sol (1863–1965)",
        symbol: "PES"
      },
      PGK: {
        displayName: "Papoea-Nieuw-Guinese kina",
        "displayName-count-one": "Papoea-Nieuw-Guinese kina",
        "displayName-count-other": "Papoea-Nieuw-Guinese kina",
        symbol: "PGK"
      },
      PHP: {
        displayName: "Filipijnse peso",
        "displayName-count-one": "Filipijnse peso",
        "displayName-count-other": "Filipijnse peso",
        symbol: "PHP",
        "symbol-alt-narrow": "₱"
      },
      PKR: {
        displayName: "Pakistaanse roepie",
        "displayName-count-one": "Pakistaanse roepie",
        "displayName-count-other": "Pakistaanse roepie",
        symbol: "PKR",
        "symbol-alt-narrow": "Rs"
      },
      PLN: {
        displayName: "Poolse zloty",
        "displayName-count-one": "Poolse zloty",
        "displayName-count-other": "Poolse zloty",
        symbol: "PLN",
        "symbol-alt-narrow": "zł"
      },
      PLZ: {
        displayName: "Poolse zloty (1950–1995)",
        "displayName-count-one": "Poolse zloty (1950–1995)",
        "displayName-count-other": "Poolse zloty (1950–1995)",
        symbol: "PLZ"
      },
      PTE: {
        displayName: "Portugese escudo",
        "displayName-count-one": "Portugese escudo",
        "displayName-count-other": "Portugese escudo",
        symbol: "PTE"
      },
      PYG: {
        displayName: "Paraguayaanse guarani",
        "displayName-count-one": "Paraguayaanse guarani",
        "displayName-count-other": "Paraguayaanse guarani",
        symbol: "PYG",
        "symbol-alt-narrow": "₲"
      },
      QAR: {
        displayName: "Qatarese rial",
        "displayName-count-one": "Qatarese rial",
        "displayName-count-other": "Qatarese rial",
        symbol: "QAR"
      },
      RHD: {
        displayName: "Rhodesische dollar",
        "displayName-count-one": "Rhodesische dollar",
        "displayName-count-other": "Rhodesische dollar",
        symbol: "RHD"
      },
      ROL: {
        displayName: "Oude Roemeense leu",
        "displayName-count-one": "Oude Roemeense leu",
        "displayName-count-other": "Oude Roemeense leu",
        symbol: "ROL"
      },
      RON: {
        displayName: "Roemeense leu",
        "displayName-count-one": "Roemeense leu",
        "displayName-count-other": "Roemeense leu",
        symbol: "RON",
        "symbol-alt-narrow": "lei"
      },
      RSD: {
        displayName: "Servische dinar",
        "displayName-count-one": "Servische dinar",
        "displayName-count-other": "Servische dinar",
        symbol: "RSD"
      },
      RUB: {
        displayName: "Russische roebel",
        "displayName-count-one": "Russische roebel",
        "displayName-count-other": "Russische roebel",
        symbol: "RUB",
        "symbol-alt-narrow": "₽"
      },
      RUR: {
        displayName: "Russische roebel (1991–1998)",
        "displayName-count-one": "Russische roebel (1991–1998)",
        "displayName-count-other": "Russische roebel (1991–1998)",
        symbol: "RUR",
        "symbol-alt-narrow": "р."
      },
      RWF: {
        displayName: "Rwandese frank",
        "displayName-count-one": "Rwandese frank",
        "displayName-count-other": "Rwandese frank",
        symbol: "RWF",
        "symbol-alt-narrow": "RF"
      },
      SAR: {
        displayName: "Saoedi-Arabische riyal",
        "displayName-count-one": "Saoedi-Arabische riyal",
        "displayName-count-other": "Saoedi-Arabische riyal",
        symbol: "SAR"
      },
      SBD: {
        displayName: "Salomon-dollar",
        "displayName-count-one": "Salomon-dollar",
        "displayName-count-other": "Salomon-dollar",
        symbol: "SI$",
        "symbol-alt-narrow": "$"
      },
      SCR: {
        displayName: "Seychelse roepie",
        "displayName-count-one": "Seychelse roepie",
        "displayName-count-other": "Seychelse roepie",
        symbol: "SCR"
      },
      SDD: {
        displayName: "Soedanese dinar",
        "displayName-count-one": "Soedanese dinar",
        "displayName-count-other": "Soedanese dinar",
        symbol: "SDD"
      },
      SDG: {
        displayName: "Soedanees pond",
        "displayName-count-one": "Soedanees pond",
        "displayName-count-other": "Soedanees pond",
        symbol: "SDG"
      },
      SDP: {
        displayName: "Soedanees pond (1957–1998)",
        "displayName-count-one": "Soedanees pond (1957–1998)",
        "displayName-count-other": "Soedanees pond (1957–1998)",
        symbol: "SDP"
      },
      SEK: {
        displayName: "Zweedse kroon",
        "displayName-count-one": "Zweedse kroon",
        "displayName-count-other": "Zweedse kronen",
        symbol: "SEK",
        "symbol-alt-narrow": "kr"
      },
      SGD: {
        displayName: "Singaporese dollar",
        "displayName-count-one": "Singaporese dollar",
        "displayName-count-other": "Singaporese dollar",
        symbol: "SGD",
        "symbol-alt-narrow": "$"
      },
      SHP: {
        displayName: "Sint-Heleens pond",
        "displayName-count-one": "Sint-Heleens pond",
        "displayName-count-other": "Sint-Heleens pond",
        symbol: "SHP",
        "symbol-alt-narrow": "£"
      },
      SIT: {
        displayName: "Sloveense tolar",
        "displayName-count-one": "Sloveense tolar",
        "displayName-count-other": "Sloveense tolar",
        symbol: "SIT"
      },
      SKK: {
        displayName: "Slowaakse koruna",
        "displayName-count-one": "Slowaakse koruna",
        "displayName-count-other": "Slowaakse koruna",
        symbol: "SKK"
      },
      SLE: {
        displayName: "SLE",
        symbol: "SLE"
      },
      SLL: {
        displayName: "Sierraleoonse leone",
        "displayName-count-one": "Sierraleoonse leone",
        "displayName-count-other": "Sierraleoonse leone",
        symbol: "SLL"
      },
      SOS: {
        displayName: "Somalische shilling",
        "displayName-count-one": "Somalische shilling",
        "displayName-count-other": "Somalische shilling",
        symbol: "SOS"
      },
      SRD: {
        displayName: "Surinaamse dollar",
        "displayName-count-one": "Surinaamse dollar",
        "displayName-count-other": "Surinaamse dollar",
        symbol: "SRD",
        "symbol-alt-narrow": "$"
      },
      SRG: {
        displayName: "Surinaamse gulden",
        "displayName-count-one": "Surinaamse gulden",
        "displayName-count-other": "Surinaamse gulden",
        symbol: "SRG"
      },
      SSP: {
        displayName: "Zuid-Soedanees pond",
        "displayName-count-one": "Zuid-Soedanees pond",
        "displayName-count-other": "Zuid-Soedanees pond",
        symbol: "SSP",
        "symbol-alt-narrow": "£"
      },
      STD: {
        displayName: "Santomese dobra (1977–2017)",
        "displayName-count-one": "Santomese dobra (1977–2017)",
        "displayName-count-other": "Santomese dobra (1977–2017)",
        symbol: "STD"
      },
      STN: {
        displayName: "Santomese dobra",
        "displayName-count-one": "Santomese dobra",
        "displayName-count-other": "Santomese dobra",
        symbol: "STN",
        "symbol-alt-narrow": "Db"
      },
      SUR: {
        displayName: "Sovjet-roebel",
        "displayName-count-one": "Sovjet-roebel",
        "displayName-count-other": "Sovjet-roebel",
        symbol: "SUR"
      },
      SVC: {
        displayName: "Salvadoraanse colón",
        "displayName-count-one": "Salvadoraanse colón",
        "displayName-count-other": "Salvadoraanse colón",
        symbol: "SVC"
      },
      SYP: {
        displayName: "Syrisch pond",
        "displayName-count-one": "Syrisch pond",
        "displayName-count-other": "Syrisch pond",
        symbol: "SYP",
        "symbol-alt-narrow": "£"
      },
      SZL: {
        displayName: "Swazische lilangeni",
        "displayName-count-one": "Swazische lilangeni",
        "displayName-count-other": "Swazische lilangeni",
        symbol: "SZL"
      },
      THB: {
        displayName: "Thaise baht",
        "displayName-count-one": "Thaise baht",
        "displayName-count-other": "Thaise baht",
        symbol: "฿",
        "symbol-alt-narrow": "฿"
      },
      TJR: {
        displayName: "Tadzjikistaanse roebel",
        "displayName-count-one": "Tadzjikistaanse roebel",
        "displayName-count-other": "Tadzjikistaanse roebel",
        symbol: "TJR"
      },
      TJS: {
        displayName: "Tadzjiekse somoni",
        "displayName-count-one": "Tadzjiekse somoni",
        "displayName-count-other": "Tadzjiekse somoni",
        symbol: "TJS"
      },
      TMM: {
        displayName: "Turkmeense manat (1993–2009)",
        "displayName-count-one": "Turkmeense manat (1993–2009)",
        "displayName-count-other": "Turkmeense manat (1993–2009)",
        symbol: "TMM"
      },
      TMT: {
        displayName: "Turkmeense manat",
        "displayName-count-one": "Turkmeense manat",
        "displayName-count-other": "Turkmeense manat",
        symbol: "TMT"
      },
      TND: {
        displayName: "Tunesische dinar",
        "displayName-count-one": "Tunesische dinar",
        "displayName-count-other": "Tunesische dinar",
        symbol: "TND"
      },
      TOP: {
        displayName: "Tongaanse paʻanga",
        "displayName-count-one": "Tongaanse paʻanga",
        "displayName-count-other": "Tongaanse paʻanga",
        symbol: "TOP",
        "symbol-alt-narrow": "T$"
      },
      TPE: {
        displayName: "Timorese escudo",
        "displayName-count-one": "Timorese escudo",
        "displayName-count-other": "Timorese escudo",
        symbol: "TPE"
      },
      TRL: {
        displayName: "Turkse lire",
        "displayName-count-one": "oude Turkse lira",
        "displayName-count-other": "oude Turkse lira",
        symbol: "TRL"
      },
      TRY: {
        displayName: "Turkse lira",
        "displayName-count-one": "Turkse lira",
        "displayName-count-other": "Turkse lira",
        symbol: "TRY",
        "symbol-alt-narrow": "₺",
        "symbol-alt-variant": "TL"
      },
      TTD: {
        displayName: "Trinidad en Tobago-dollar",
        "displayName-count-one": "Trinidad en Tobago-dollar",
        "displayName-count-other": "Trinidad en Tobago-dollar",
        symbol: "TTD",
        "symbol-alt-narrow": "$"
      },
      TWD: {
        displayName: "Nieuwe Taiwanese dollar",
        "displayName-count-one": "Nieuwe Taiwanese dollar",
        "displayName-count-other": "Nieuwe Taiwanese dollar",
        symbol: "NT$",
        "symbol-alt-narrow": "NT$"
      },
      TZS: {
        displayName: "Tanzaniaanse shilling",
        "displayName-count-one": "Tanzaniaanse shilling",
        "displayName-count-other": "Tanzaniaanse shilling",
        symbol: "TZS"
      },
      UAH: {
        displayName: "Oekraïense hryvnia",
        "displayName-count-one": "Oekraïense hryvnia",
        "displayName-count-other": "Oekraïense hryvnia",
        symbol: "UAH",
        "symbol-alt-narrow": "₴"
      },
      UAK: {
        displayName: "Oekraïense karbovanetz",
        "displayName-count-one": "Oekraïense karbovanetz",
        "displayName-count-other": "Oekraïense karbovanetz",
        symbol: "UAK"
      },
      UGS: {
        displayName: "Oegandese shilling (1966–1987)",
        "displayName-count-one": "Oegandese shilling (1966–1987)",
        "displayName-count-other": "Oegandese shilling (1966–1987)",
        symbol: "UGS"
      },
      UGX: {
        displayName: "Oegandese shilling",
        "displayName-count-one": "Oegandese shilling",
        "displayName-count-other": "Oegandese shilling",
        symbol: "UGX"
      },
      USD: {
        displayName: "Amerikaanse dollar",
        "displayName-count-one": "Amerikaanse dollar",
        "displayName-count-other": "Amerikaanse dollar",
        symbol: "US$",
        "symbol-alt-narrow": "$"
      },
      USN: {
        displayName: "Amerikaanse dollar (volgende dag)",
        "displayName-count-one": "Amerikaanse dollar (volgende dag)",
        "displayName-count-other": "Amerikaanse dollar (volgende dag)",
        symbol: "USN"
      },
      USS: {
        displayName: "Amerikaanse dollar (zelfde dag)",
        "displayName-count-one": "Amerikaanse dollar (zelfde dag)",
        "displayName-count-other": "Amerikaanse dollar (zelfde dag)",
        symbol: "USS"
      },
      UYI: {
        displayName: "Uruguayaanse peso en geïndexeerde eenheden",
        "displayName-count-one": "Uruguayaanse peso en geïndexeerde eenheden",
        "displayName-count-other": "Uruguayaanse peso en geïndexeerde eenheden",
        symbol: "UYI"
      },
      UYP: {
        displayName: "Uruguayaanse peso (1975–1993)",
        "displayName-count-one": "Uruguayaanse peso (1975–1993)",
        "displayName-count-other": "Uruguayaanse peso (1975–1993)",
        symbol: "UYP"
      },
      UYU: {
        displayName: "Uruguayaanse peso",
        "displayName-count-one": "Uruguayaanse peso",
        "displayName-count-other": "Uruguayaanse peso",
        symbol: "UYU",
        "symbol-alt-narrow": "$"
      },
      UYW: {
        displayName: "Uruguayaanse nominale salarisindexeenheid",
        "displayName-count-one": "Uruguayaanse nominale salarisindexeenheid",
        "displayName-count-other": "Uruguayaanse nominale salarisindexeenheid",
        symbol: "UYW"
      },
      UZS: {
        displayName: "Oezbeekse sum",
        "displayName-count-one": "Oezbeekse sum",
        "displayName-count-other": "Oezbeekse sum",
        symbol: "UZS"
      },
      VEB: {
        displayName: "Venezolaanse bolivar (1871–2008)",
        "displayName-count-one": "Venezolaanse bolivar (1871–2008)",
        "displayName-count-other": "Venezolaanse bolivar (1871–2008)",
        symbol: "VEB"
      },
      VED: {
        displayName: "VED",
        symbol: "VED"
      },
      VEF: {
        displayName: "Venezolaanse bolivar (2008–2018)",
        "displayName-count-one": "Venezolaanse bolivar (2008–2018)",
        "displayName-count-other": "Venezolaanse bolivar (2008–2018)",
        symbol: "VEF",
        "symbol-alt-narrow": "Bs"
      },
      VES: {
        displayName: "Venezolaanse bolivar",
        "displayName-count-one": "Venezolaanse bolivar",
        "displayName-count-other": "Venezolaanse bolivar",
        symbol: "VES"
      },
      VND: {
        displayName: "Vietnamese dong",
        "displayName-count-one": "Vietnamese dong",
        "displayName-count-other": "Vietnamese dong",
        symbol: "₫",
        "symbol-alt-narrow": "₫"
      },
      VNN: {
        displayName: "Vietnamese dong (1978–1985)",
        "displayName-count-one": "Vietnamese dong (1978–1985)",
        "displayName-count-other": "Vietnamese dong (1978–1985)",
        symbol: "VNN"
      },
      VUV: {
        displayName: "Vanuatuaanse vatu",
        "displayName-count-one": "Vanuatuaanse vatu",
        "displayName-count-other": "Vanuatuaanse vatu",
        symbol: "VUV"
      },
      WST: {
        displayName: "Samoaanse tala",
        "displayName-count-one": "Samoaanse tala",
        "displayName-count-other": "Samoaanse tala",
        symbol: "WST"
      },
      XAF: {
        displayName: "CFA-frank",
        "displayName-count-one": "CFA-frank",
        "displayName-count-other": "CFA-frank",
        symbol: "FCFA"
      },
      XAG: {
        displayName: "Zilver",
        "displayName-count-one": "Troy ounce zilver",
        "displayName-count-other": "Troy ounces zilver",
        symbol: "XAG"
      },
      XAU: {
        displayName: "Goud",
        "displayName-count-one": "Troy ounce goud",
        "displayName-count-other": "Troy ounces goud",
        symbol: "XAU"
      },
      XBA: {
        displayName: "Europese samengestelde eenheid",
        "displayName-count-one": "Europese samengestelde eenheid",
        "displayName-count-other": "Europese samengestelde eenheid",
        symbol: "XBA"
      },
      XBB: {
        displayName: "Europese monetaire eenheid",
        "displayName-count-one": "Europese monetaire eenheid",
        "displayName-count-other": "Europese monetaire eenheid",
        symbol: "XBB"
      },
      XBC: {
        displayName: "Europese rekeneenheid (XBC)",
        "displayName-count-one": "Europese rekeneenheid (XBC)",
        "displayName-count-other": "Europese rekeneenheid (XBC)",
        symbol: "XBC"
      },
      XBD: {
        displayName: "Europese rekeneenheid (XBD)",
        "displayName-count-one": "Europese rekeneenheid (XBD)",
        "displayName-count-other": "Europese rekeneenheid (XBD)",
        symbol: "XBD"
      },
      XCD: {
        displayName: "Oost-Caribische dollar",
        "displayName-count-one": "Oost-Caribische dollar",
        "displayName-count-other": "Oost-Caribische dollar",
        symbol: "EC$",
        "symbol-alt-narrow": "$"
      },
      XDR: {
        displayName: "Special Drawing Rights",
        "displayName-count-one": "Special Drawing Rights",
        "displayName-count-other": "Special Drawing Rights",
        symbol: "XDR"
      },
      XEU: {
        displayName: "European Currency Unit",
        "displayName-count-one": "European Currency Unit",
        "displayName-count-other": "European Currency Unit",
        symbol: "XEU"
      },
      XFO: {
        displayName: "Franse gouden franc",
        "displayName-count-one": "Franse gouden franc",
        "displayName-count-other": "Franse gouden franc",
        symbol: "XFO"
      },
      XFU: {
        displayName: "Franse UIC-franc",
        "displayName-count-one": "Franse UIC-franc",
        "displayName-count-other": "Franse UIC-franc",
        symbol: "XFU"
      },
      XOF: {
        displayName: "CFA-franc BCEAO",
        "displayName-count-one": "CFA-franc BCEAO",
        "displayName-count-other": "CFA-franc BCEAO",
        symbol: "F CFA"
      },
      XPD: {
        displayName: "Palladium",
        "displayName-count-one": "Troy ounce palladium",
        "displayName-count-other": "Troy ounces palladium",
        symbol: "XPD"
      },
      XPF: {
        displayName: "CFP-frank",
        "displayName-count-one": "CFP-frank",
        "displayName-count-other": "CFP-frank",
        symbol: "XPF"
      },
      XPT: {
        displayName: "Platina",
        "displayName-count-one": "Troy ounce platina",
        "displayName-count-other": "Troy ounces platina",
        symbol: "XPT"
      },
      XRE: {
        displayName: "RINET-fondsen",
        "displayName-count-one": "RINET-fondsen",
        "displayName-count-other": "RINET-fondsen",
        symbol: "XRE"
      },
      XSU: {
        displayName: "Sucre",
        "displayName-count-one": "Sucre",
        "displayName-count-other": "Sucre",
        symbol: "XSU"
      },
      XTS: {
        displayName: "Valutacode voor testdoeleinden",
        "displayName-count-one": "Valutacode voor testdoeleinden",
        "displayName-count-other": "Valutacode voor testdoeleinden",
        symbol: "XTS"
      },
      XUA: {
        displayName: "ADB-rekeneenheid",
        "displayName-count-one": "ADB-rekeneenheid",
        "displayName-count-other": "ADB-rekeneenheid",
        symbol: "XUA"
      },
      XXX: {
        displayName: "onbekende munteenheid",
        "displayName-count-one": "onbekende munteenheid",
        "displayName-count-other": "onbekende munteenheid",
        symbol: "XXX"
      },
      YDD: {
        displayName: "Jemenitische dinar",
        "displayName-count-one": "Jemenitische dinar",
        "displayName-count-other": "Jemenitische dinar",
        symbol: "YDD"
      },
      YER: {
        displayName: "Jemenitische rial",
        "displayName-count-one": "Jemenitische rial",
        "displayName-count-other": "Jemenitische rial",
        symbol: "YER"
      },
      YUD: {
        displayName: "Joegoslavische harde dinar",
        "displayName-count-one": "Joegoslavische harde dinar",
        "displayName-count-other": "Joegoslavische harde dinar",
        symbol: "YUD"
      },
      YUM: {
        displayName: "Joegoslavische noviy-dinar",
        "displayName-count-one": "Joegoslavische noviy-dinar",
        "displayName-count-other": "Joegoslavische noviy-dinar",
        symbol: "YUM"
      },
      YUN: {
        displayName: "Joegoslavische convertibele dinar",
        "displayName-count-one": "Joegoslavische convertibele dinar",
        "displayName-count-other": "Joegoslavische convertibele dinar",
        symbol: "YUN"
      },
      YUR: {
        displayName: "Joegoslavische hervormde dinar (1992–1993)",
        "displayName-count-one": "Joegoslavische hervormde dinar (1992–1993)",
        "displayName-count-other": "Joegoslavische hervormde dinar (1992–1993)",
        symbol: "YUR"
      },
      ZAL: {
        displayName: "Zuid-Afrikaanse rand (financieel)",
        "displayName-count-one": "Zuid-Afrikaanse rand (financieel)",
        "displayName-count-other": "Zuid-Afrikaanse rand (financieel)",
        symbol: "ZAL"
      },
      ZAR: {
        displayName: "Zuid-Afrikaanse rand",
        "displayName-count-one": "Zuid-Afrikaanse rand",
        "displayName-count-other": "Zuid-Afrikaanse rand",
        symbol: "ZAR",
        "symbol-alt-narrow": "R"
      },
      ZMK: {
        displayName: "Zambiaanse kwacha (1968–2012)",
        "displayName-count-one": "Zambiaanse kwacha (1968–2012)",
        "displayName-count-other": "Zambiaanse kwacha (1968–2012)",
        symbol: "ZMK"
      },
      ZMW: {
        displayName: "Zambiaanse kwacha",
        "displayName-count-one": "Zambiaanse kwacha",
        "displayName-count-other": "Zambiaanse kwacha",
        symbol: "ZMW",
        "symbol-alt-narrow": "ZK"
      },
      ZRN: {
        displayName: "Zaïrese nieuwe zaïre",
        "displayName-count-one": "Zaïrese nieuwe zaïre",
        "displayName-count-other": "Zaïrese nieuwe zaïre",
        symbol: "ZRN"
      },
      ZRZ: {
        displayName: "Zaïrese zaïre",
        "displayName-count-one": "Zaïrese zaïre",
        "displayName-count-other": "Zaïrese zaïre",
        symbol: "ZRZ"
      },
      ZWD: {
        displayName: "Zimbabwaanse dollar",
        "displayName-count-one": "Zimbabwaanse dollar",
        "displayName-count-other": "Zimbabwaanse dollar",
        symbol: "ZWD"
      },
      ZWL: {
        displayName: "Zimbabwaanse dollar (2009)",
        "displayName-count-one": "Zimbabwaanse dollar (2009)",
        "displayName-count-other": "Zimbabwaanse dollar (2009)",
        symbol: "ZWL"
      },
      ZWR: {
        displayName: "Zimbabwaanse dollar (2008)",
        "displayName-count-one": "Zimbabwaanse dollar (2008)",
        "displayName-count-other": "Zimbabwaanse dollar (2008)",
        symbol: "ZWR"
      }
    },
    localeCurrency: "EUR"
  },
  calendar: {
    patterns: {
      d: "d/MM/y",
      D: "EEEE d MMMM y",
      m: "d MMM",
      M: "d MMMM",
      y: "MMM y",
      Y: "MMMM y",
      F: "EEEE d MMMM y HH:mm:ss",
      g: "d/M/y HH:mm",
      G: "d/M/y HH:mm:ss",
      t: "HH:mm",
      T: "HH:mm:ss",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} 'om' {0}",
      long: "{1} 'om' {0}",
      medium: "{1} {0}",
      short: "{1} {0}",
      availableFormats: {
        Bh: "h B",
        Bhm: "h:mm B",
        Bhms: "h:mm:ss B",
        d: "d",
        E: "ccc",
        EBhm: "E h:mm B",
        EBhms: "E h:mm:ss B",
        Ed: "E d",
        Ehm: "E h:mm a",
        EHm: "E HH:mm",
        Ehms: "E h:mm:ss a",
        EHms: "E HH:mm:ss",
        Gy: "y G",
        GyMd: "d/M/y GGGGG",
        GyMMM: "MMM y G",
        GyMMMd: "d MMM y G",
        GyMMMEd: "E d MMM y G",
        h: "h a",
        H: "HH",
        hm: "h:mm a",
        Hm: "HH:mm",
        hms: "h:mm:ss a",
        Hms: "HH:mm:ss",
        hmsv: "h:mm:ss a v",
        Hmsv: "HH:mm:ss v",
        hmv: "h:mm a v",
        Hmv: "HH:mm v",
        M: "L",
        Md: "d/M",
        MEd: "E d/M",
        MMM: "LLL",
        MMMd: "d MMM",
        MMMEd: "E d MMM",
        MMMMd: "d MMMM",
        "MMMMW-count-one": "'week' W 'van' MMMM",
        "MMMMW-count-other": "'week' W 'van' MMMM",
        ms: "mm:ss",
        y: "y",
        yM: "M/y",
        yMd: "d/M/y",
        yMEd: "E d/M/y",
        yMMM: "MMM y",
        yMMMd: "d MMM y",
        yMMMEd: "E d MMM y",
        yMMMM: "MMMM y",
        yQQQ: "QQQ y",
        yQQQQ: "QQQQ y",
        "yw-count-one": "'week' w 'in' Y",
        "yw-count-other": "'week' w 'in' Y"
      }
    },
    timeFormats: {
      full: "HH:mm:ss zzzz",
      long: "HH:mm:ss z",
      medium: "HH:mm:ss",
      short: "HH:mm"
    },
    dateFormats: {
      full: "EEEE d MMMM y",
      long: "d MMMM y",
      medium: "d MMM y",
      short: "d/MM/y"
    },
    days: {
      format: {
        abbreviated: ["zo", "ma", "di", "wo", "do", "vr", "za"],
        narrow: ["Z", "M", "D", "W", "D", "V", "Z"],
        short: ["zo", "ma", "di", "wo", "do", "vr", "za"],
        wide: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"]
      },
      "stand-alone": {
        abbreviated: ["zo", "ma", "di", "wo", "do", "vr", "za"],
        narrow: ["Z", "M", "D", "W", "D", "V", "Z"],
        short: ["zo", "ma", "di", "wo", "do", "vr", "za"],
        wide: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"]
      }
    },
    months: {
      format: {
        abbreviated: ["jan.", "feb.", "mrt.", "apr.", "mei", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."],
        narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
        wide: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"]
      },
      "stand-alone": {
        abbreviated: ["jan.", "feb.", "mrt.", "apr.", "mei", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."],
        narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
        wide: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["K1", "K2", "K3", "K4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["1e kwartaal", "2e kwartaal", "3e kwartaal", "4e kwartaal"]
      },
      "stand-alone": {
        abbreviated: ["K1", "K2", "K3", "K4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["1e kwartaal", "2e kwartaal", "3e kwartaal", "4e kwartaal"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "middernacht",
          am: "a.m.",
          pm: "p.m.",
          morning1: "’s ochtends",
          afternoon1: "’s middags",
          evening1: "’s avonds",
          night1: "’s nachts"
        },
        narrow: {
          midnight: "middernacht",
          am: "a.m.",
          pm: "p.m.",
          morning1: "’s ochtends",
          afternoon1: "’s middags",
          evening1: "’s avonds",
          night1: "’s nachts"
        },
        wide: {
          midnight: "middernacht",
          am: "a.m.",
          pm: "p.m.",
          morning1: "’s ochtends",
          afternoon1: "’s middags",
          evening1: "’s avonds",
          night1: "’s nachts"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "middernacht",
          am: "a.m.",
          pm: "p.m.",
          morning1: "ochtend",
          afternoon1: "middag",
          evening1: "avond",
          night1: "nacht"
        },
        narrow: {
          midnight: "middernacht",
          am: "a.m.",
          pm: "p.m.",
          morning1: "ochtend",
          afternoon1: "middag",
          evening1: "avond",
          night1: "nacht"
        },
        wide: {
          midnight: "middernacht",
          am: "a.m.",
          pm: "p.m.",
          morning1: "ochtend",
          afternoon1: "middag",
          evening1: "avond",
          night1: "nacht"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "voor Christus",
          "1": "na Christus",
          "0-alt-variant": "vóór gewone jaartelling",
          "1-alt-variant": "gewone jaartelling"
        },
        abbreviated: {
          "0": "v.Chr.",
          "1": "n.Chr.",
          "0-alt-variant": "v.g.j.",
          "1-alt-variant": "g.j."
        },
        narrow: {
          "0": "v.C.",
          "1": "n.C.",
          "0-alt-variant": "vgj",
          "1-alt-variant": "gj"
        }
      }
    },
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    dateFields: {
      era: {
        wide: "tijdperk",
        short: "tijdperk",
        narrow: "tijdperk"
      },
      year: {
        wide: "jaar",
        short: "jr",
        narrow: "jr"
      },
      quarter: {
        wide: "kwartaal",
        short: "kwartaal",
        narrow: "kwartaal"
      },
      month: {
        wide: "maand",
        short: "mnd",
        narrow: "mnd"
      },
      week: {
        wide: "week",
        short: "wk",
        narrow: "wk"
      },
      weekOfMonth: {
        wide: "week van de maand",
        short: "wk van de mnd",
        narrow: "wk v.d. mnd"
      },
      day: {
        wide: "dag",
        short: "dag",
        narrow: "dag"
      },
      dayOfYear: {
        wide: "dag van het jaar",
        short: "dag van het jr",
        narrow: "dag v.h. jr"
      },
      weekday: {
        wide: "dag van de week",
        short: "dag van de wk",
        narrow: "dag v.d. wk"
      },
      weekdayOfMonth: {
        wide: "weekdag van de maand",
        short: "wkdag van de mnd",
        narrow: "wkdag v.d. mnd"
      },
      dayperiod: {
        short: "a.m./p.m.",
        wide: "a.m./p.m.",
        narrow: "a.m./p.m."
      },
      hour: {
        wide: "uur",
        short: "uur",
        narrow: "u"
      },
      minute: {
        wide: "minuut",
        short: "min",
        narrow: "min"
      },
      second: {
        wide: "seconde",
        short: "sec",
        narrow: "s"
      },
      zone: {
        wide: "tijdzone",
        short: "zone",
        narrow: "zone"
      },
      millisecond: {
        narrow: "ms",
        short: "ms",
        wide: "milliseconde"
      }
    }
  },
  firstDay: 1,
  weekendRange: {
    start: 6,
    end: 0
  },
  likelySubtags: {
    nl: "nl-Latn-NL"
  }
});