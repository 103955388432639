import { Component } from '@angular/core';

import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'rc-app-bar',
  standalone: true,
  templateUrl: './app-bar.component.html',
  styleUrl: './app-bar.component.scss',
  imports: [UserMenuComponent],
})
export class AppBarComponent {}
