import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AsyncPipe } from '@angular/common';
import { UserNotificationsComponent } from '@core/components/user-notifications/user-notifications.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'rc-user-menu',
  standalone: true,
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
  imports: [
    AsyncPipe,
    UserNotificationsComponent,
  ],
})
export class UserMenuComponent {
  authService = inject(AuthService);
  menuItems = [
    {
      text: 'Logout',
      click: () => this.logout(),
    },
  ];

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logout({
      logoutParams: {
        federated: true,
        clientId: environment.auth0.clientId,
        returnTo: window.location.origin,
      },
    });
  }
}
