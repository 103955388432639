import { Component, inject, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth0/auth0-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AppBarComponent } from '@core/components/app-bar/app-bar.component';
import { PrimaryNavComponent } from '@core/components/primary-nav/primary-nav.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { filter, first } from 'rxjs/operators';
import { Observable, switchMap } from 'rxjs';
import { ApiHttpService } from '@core/services/api-http.service';
import { OverviewCreationService } from '@core/services/overview-creation.service';

@Component({
  selector: 'rc-app',
  standalone: true,
  imports: [RouterOutlet, AppBarComponent, PrimaryNavComponent, DialogModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  #translateService = inject(TranslateService);
  #authService = inject(AuthService);
  #apiService = inject(ApiHttpService);
  #router = inject(Router);
  #navigationEnd: Observable<NavigationEnd>;
  #currentUrl: string;
  #overviewCreationService = inject(OverviewCreationService);
  constructor() {
    this.#translateService.setDefaultLang('nl');
    this.redirectIfNotLoggedIn();
    this.#authService.idTokenClaims$
      .pipe(
        filter((token) => !!token),
        first(),
        switchMap(() => this.#apiService.get('/cookie')),
      )
      .subscribe();

    this.#navigationEnd = this.#router.events.pipe(
      filter((e: Event) => e instanceof NavigationEnd),
    ) as Observable<NavigationEnd>;
  }

  ngOnInit() {
    this.#navigationEnd.subscribe((value) => {
      if (value.url.split('?')[0] !== this.#currentUrl) {
        this.#currentUrl = value.url.split('?')[0];
        this.#overviewCreationService.setCreationSubject(undefined);
      }
    });
  }

  redirectIfNotLoggedIn(): void {
    this.#authService.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.#authService.loginWithRedirect({ authorizationParams: { redirectUrl: '/dashboard', prompt: 'login' } });
      }
    });
  }
}
