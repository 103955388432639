<span kendoButton #notificationAnchor class="k-button-clear notifications-button" (click)="toggleNotifications()">
  <i [ngClass]="notificationService.amountToRead > 0 ? 'notification-unread-icon' : 'notification-icon'"></i></span>
@if (showNotificationsPopUp) {
  <kendo-popup
    [anchor]="notificationAnchor.element"
    [popupAlign]="{horizontal: 'right', vertical: 'top'}"
    (anchorViewportLeave)="toggleNotifications()"
    class="user-notification-popup"
  >
    <div class="user-notification-content">
      <div class="title-row k-d-flex k-justify-content-between k-align-items-center">
        <div class="k-d-flex k-align-items-center k-gap-2">
          <h3 class="title">{{ 'NOTIFICATIONS_TITLE' | translate | capitalize }}</h3>
          <span class="number-highlight">{{ notificationService.amountToRead }}</span>
        </div>
        @if (notificationService.amountToRead > 0) {
          <a (click)="notificationService.markAllAsRead()">
            {{ 'NOTIFICATIONS_MARK_ALL_READ' | translate | capitalize }}
          </a>
        }
      </div>

      <kendo-grid
        [data]="gridData$ | async"
        [loading]="loading"
        [hideHeader]="true"
        (scrollBottom)="loadMore()"
        [height]="352"
      >
        <kendo-grid-column field="title">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="notification-item k-pb-4">
             <span class="k-d-flex k-align-items-center k-justify-content-between">
                  <p class="title {{dataItem.isRead ? 'read' : ''}}">{{ dataItem.title }}</p>
               @if (!dataItem.isRead) {
                 <span class="notification-pulse"></span>
               }

             </span>
              <p class="description"> {{ dataItem.createdOn | kendoDate: "dd/MM/yyyy" }}
                • {{ dataItem.createdOn | kendoDate: "HH:mm:ss" }} {{ dataItem.description }}</p>
              <span class="k-d-flex k-gap-5">
                <button kendoButton type='button' class="k-button-solid-primary"
                        [disabled]="dataItem.isRead">{{ 'OPEN' | translate | capitalize }}
              </button>
              <button kendoButton type='button' class="k-button-solid-secondary" [disabled]="!!dataItem.isRead"
                      (click)="notificationService.markRead(dataItem)"> {{ 'MARK_AS_READ' | translate | capitalize }}
              </button>
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </kendo-popup>
}

