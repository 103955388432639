<rc-app-bar></rc-app-bar>

<main class="main container">
  <rc-primary-nav aria-label="Primary"></rc-primary-nav>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
</main>
<div kendoDialogContainer></div>
