import { inject, Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  zone = inject(NgZone);

  getServerSentEvent(url: string): Observable<MessageEvent> {
    return new Observable(observer => {
      const eventSource = new EventSource(url);

      eventSource.onmessage = event => {
        this.zone.run(() => observer.next(event));
      };

      eventSource.onerror = error => {
        this.zone.run(() => {
          console.error('EventSource error:', error);
          observer.error(error);
        });
      };

      return () => {
        eventSource.close();
      };
    });
  }
}
