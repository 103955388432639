import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/dashboard/routes').then((m) => m.DASHBOARD_ROUTES),
  },
  {
    path: 'clients',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/clients/routes').then((m) => m.CLIENT_ROUTES),
  },
  {
    path: 'suppliers',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/suppliers/routes').then((m) => m.SUPPLIER_ROUTES),
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/orders/routes').then((m) => m.ORDER_ROUTES),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/settings/routes').then((m) => m.SETTING_ROUTES),
  },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard' },
];
