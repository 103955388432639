import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'rc-primary-nav',
  standalone: true,
  templateUrl: './primary-nav.component.html',
  styleUrl: './primary-nav.component.scss',
  imports: [RouterLink, RouterLinkActive, TooltipModule, TranslateModule],
})
export class PrimaryNavComponent {}
